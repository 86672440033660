
// export const consoleText = `
//       ___           ___           ___       ___       ___     
//      /\__\         /\  \         /\__\     /\__\     /\  \    
//     /:/  /        /::\  \       /:/  /    /:/  /    /::\  \   
//    /:/__/        /:/\:\  \     /:/  /    /:/  /    /:/\:\  \  
//   /::\  \ ___   /::\~\:\  \   /:/  /    /:/  /    /:/  \:\  \ 
//  /:/\:\  /\__\ /:/\:\ \:\__\ /:/__/    /:/__/    /:/__/ \:\__\
//  \/__\:\/:/  / \:\~\:\ \/__/ \:\  \    \:\  \    \:\  \ /:/  /
//       \::/  /   \:\ \:\__\    \:\  \    \:\  \    \:\  /:/  / 
//       /:/  /     \:\ \/__/     \:\  \    \:\  \    \:\/:/  /  
//      /:/  /       \:\__\        \:\__\    \:\__\    \::/  /   
//      \/__/         \/__/         \/__/     \/__/     \/__/    
// `

// export const consoleTextRaw = "\n      ___           ___           ___       ___       ___     \n     /__         /           /__     /__     /      \n    /:/  /        /::         /:/  /    /:/  /    /::     \n   /:/__/        /:/:       /:/  /    /:/  /    /:/:    \n  /::   ___   /::~:     /:/  /    /:/  /    /:/  :   \n /:/:  /__ /:/: :__ /:/__/    /:/__/    /:/__/ :__ /__:/:/  / :~: /__/ :      :      :   /:/  /\n      ::/  /   : :__    :      :      :  /:/  / \n      /:/  /     : /__/     :      :      :/:/  /  \n     /:/  /       :__        :__    :__    ::/  /   \n     /__/         /__/         /__/     /__/     /__/    \n";

export const scrollIds = {
      'home': '#Home',
      'me': '#Me',
      'interests': '#Interests',
      'cv': '#CV',
      'skillset': '#Skillset',
      'tools': '#Tools',
      'current': '#Current',
      'contact': '#Contact'
};

export const aboutMeText = `My name is Gustav. I'm a quality driven software engineer currently working with full stack JavaScript development. I love building highly performant, high quality web sites that delight users!`


export const tech = {
      'js': '- JavaScript - ECMAScript 6, React, Node.js',
      'html': '- HTML5 - CSS3, Sass, Bootstrap 4',
      'build': '- Build tools/CI - Webpack, Jenkins, TeamCity, Docker',
      'hosting': '- Hosting - Google Cloud Platform, Amazon Web Services'
}