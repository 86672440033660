import React, { Component } from 'react';
import { navigate } from 'gatsby';
import './header.scss';

class Header extends Component {

    constructor(props){
        super(props);
        this.state = {
            isIE: false
        };
    }

    handleScrollEvent() {
        const currentScrollPos = window.pageYOffset;
        if (currentScrollPos > 100) {
            document.getElementById("navbar").style.opacity = "0.9";
        } else {
            document.getElementById("navbar").style.opacity = "0";
        }
    }

    handleTitleClick() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
            passive: true
        });
        navigate('/');
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScrollEvent, { passive: true });
        if (window.navigator.userAgent.match(/(MSIE|Trident)/)) {
            this.setState({
                isIE: true
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScrollEvent, { passive: true });
    }

    render() {
        return(
            <>
            {this.state.isIE && 
                <div className="d-flex fixed-top w-100 text-center justify-content-center py-3 bg-warning" style={{zIndex: '999'}}>
                    <h1 className="mt-2">Please upgrade the browser you are using <span role="img" aria-label="Fire">🔥</span></h1>
                </div>
            }
            <header className="header" id="navbar">
                <div className="title ml-sm-0 ml-4" onClick={() => this.handleTitleClick()}>
                    Gustav Beck-Norén
                </div>
            </header>
            </>
        );
    }
}

export default Header;