import React, { PureComponent, Fragment } from 'react';
import { Link, navigate } from 'gatsby';
import posed from "react-pose";
import './hamburger.scss';
import { scrollIds } from '../../constants/strings';


const Sidebar = posed.ul({
    open: {
      x: '0%',
      delayChildren: 200,
      staggerChildren: 50
    },
    closed: { x: '-100%', delay: 300 }
  });
  
  const Item = posed.li({
    open: { y: 0, opacity: 1 },
    closed: { y: 20, opacity: 0 }
  });

class Hamburger extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            isOpen: false
        };
        this.deferredPrompt = null;
    }

    componentDidMount() {
        document.addEventListener('keydown', (e) => {
            if(e.keyCode === 27) {
                this.handleEscapePress();
            }
        });
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.deferredPrompt = e;
            console.log('PWA can be installed');
        });
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleEscapePress(), true);
    }

    handleEscapePress() {
        this.setState({
            isOpen: false
        });
    }

    handleHamburgerClick() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleMenuClick(id) {
        document.getElementById(id).scrollIntoView({behavior: 'smooth', block: 'start', passive: true});
        navigate(`/${id}`);
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleInstallClick() {
        // Show the prompt
        this.deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice
            .then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                this.deferredPrompt = null;
        });
    }

    render() {

        const { home, me, skillset, contact, cv } = scrollIds;

        const { isOpen } = this.state;

        return (
            <Fragment>
                <button className={`hamburger hamburger--arrow ${isOpen ? 'is-active' : ''}`}
                        type="button"
                        aria-label="Side menu"
                        onClick={() => this.handleHamburgerClick()}>
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
                <Sidebar className="sidebar" pose={isOpen ? 'open' : 'closed'}>
                    <Item className="item" onClick={()=> this.handleMenuClick(home)} role="link">
                        <Link to={`/${home}`} className="item-text">Home</Link>
                    </Item>
                    <Item className="item" onClick={()=> this.handleMenuClick(me)}>
                        <Link to={`/${me}`} className="item-text">Me</Link>
                    </Item>
                    <Item className="item" onClick={()=> this.handleMenuClick(cv)}>
                        <Link to={`/${cv}`} className="item-text">CV</Link>
                    </Item>
                    <Item className="item" onClick={()=> this.handleMenuClick(skillset)}>
                        <Link to={`/${skillset}`} className="item-text">Skillset</Link>
                    </Item>
                    <Item className="item" onClick={()=> this.handleMenuClick(contact)}>
                        <Link to={`/${contact}`} className="item-text">Contact</Link>
                    </Item>
                    {this.deferredPrompt &&
                        <Item className="item mt-auto mb-4" onClick={()=> this.handleInstallClick()}>
                            <span className="item-text ml-2 mr-3 text-center">
                                <span className="mr-2" role="img" aria-label="Phone">📲</span>Install as Progressive Web App to your device!
                            </span>
                        </Item>
                    }
                </Sidebar>
                <div className={`sidebar-overlay ${isOpen ? 'is-active' : ''}`} onClick={() => this.handleHamburgerClick()}/>
            </Fragment>

        );
    };

}

export default Hamburger;